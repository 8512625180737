@file:JvmName("CommonUtils")
package utils

import io.github.aakira.napier.Napier
import kotlin.jvm.JvmName

expect fun formatCurrentTimeAsHHmmss(): String
expect fun formatCurrentYearAsInt(): Int


/*
 * Prints a message to the console (deprecated)
 */
fun myLog(message: String) = Napier.d(message)
fun myLogError(message: String) = Napier.e(message)


