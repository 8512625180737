package utils

import io.github.aakira.napier.Antilog
import io.github.aakira.napier.LogLevel

expect fun getPlatformName(): String // actuals are in shared/

class CustomAntilog : Antilog() {

    override fun performLog(
        priority: LogLevel,
        tag: String?,
        throwable: Throwable?,
        message: String?
    ) {
        // Map log levels to visual indicators and colors
        val priorityChar = when (priority) {
            LogLevel.VERBOSE -> 'V'
            LogLevel.DEBUG -> 'D'
            LogLevel.INFO -> 'I'
            LogLevel.WARNING -> 'W'
            LogLevel.ERROR -> 'E'
            LogLevel.ASSERT -> 'A'
        }

        val color = when (priority) {
            LogLevel.VERBOSE -> "\u001B[37m" // White
            LogLevel.DEBUG -> "\u001B[34m"   // Blue
            LogLevel.INFO -> "\u001B[32m"    // Green
            LogLevel.WARNING -> "\u001B[33m" // Yellow
            LogLevel.ERROR -> "\u001B[31m"   // Red
            LogLevel.ASSERT -> "\u001B[35m"  // Magenta
        }

        val resetColor = "\u001B[0m"
        val timestamp = formatCurrentTimeAsHHmmss()
        val refTag = tag ?: getPlatformName()

        println("$color[$timestamp] $priorityChar/$refTag: $message$resetColor")
        throwable?.printStackTrace()
    }
}