package auth

import database.CustomerSession
import io.github.aakira.napier.Napier
import io.github.jan.supabase.auth.auth
import io.github.jan.supabase.auth.providers.builtin.Email
import io.github.jan.supabase.auth.user.UserSession
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.serialization.json.Json
import model.ServerLoginResponse
import model.UserLoginParams
import session.HttpSession
import session.SessionStorage
import session.SupabaseSession

/*
 * Packages the currently active session and stores it in SessionStorage.
 */
class SessionManagementService {
    suspend fun processAndStoreSession(): ServerLoginResponse {
        val id = "processAndStoreSession"
        val authClient = SupabaseSession.client().auth
        val session = authClient.currentSessionOrNull() ?: throw IllegalStateException("No session found")

        // The session should not be stale, because it comes from one of two places, that leave it in a known state:
        // 1. The user just logged in, and we stored the (active) session.
        // 2. The app just refreshed the session from SessionStorage, refreshed it and imported it. So we have an
        // active session.

        try {
            // Package active session for storage
            Napier.d("Packaging active session for storage", tag=id)
            val response = HttpSession.client.post("/auth/package-session") {
                contentType(ContentType.Application.Json)
                setBody(session)
            }

            // Store the encrypted session
            val encryptedSession = response.bodyAsText()
            SessionStorage.storeSession(encryptedSession)
            Napier.d("Session stored successfully", tag=id)

            return ServerLoginResponse(
                success = true,
                message = "Session processed successfully"
            )

        } catch (e: Exception) {
            Napier.e("Session processing failed: ${e.message}", tag=id)
            SessionStorage.clearSession()
            return ServerLoginResponse(
                success = false,
                message = e.message ?: "Session processing failed"
            )
        }
    }
}

suspend fun loginUser(userParams: UserLoginParams): ServerLoginResponse {
    val id = "loginUser"
    try {
        // Direct Supabase authentication
        SupabaseSession.client().auth.signInWith(Email) {
            email = userParams.email
            password = userParams.password
            captchaToken = userParams.captchaToken
        }

        // Get the current session
        val session = SupabaseSession.client().auth.currentSessionOrNull()
            ?: throw IllegalStateException("Login succeeded but no session found")

        // Load the customer data
        CustomerSession.load(session.user!!)
        Napier.d("Customer session loaded", tag=id)

        return SessionManagementService().processAndStoreSession()
    } catch (e: Exception) {
        val msg = if (e.message?.contains("invalid_grant") == true) {
            val start = e.message!!.indexOf("(")
            val end = e.message!!.indexOf(")")
            if (start != -1 && end != -1) {
                e.message!!.substring(start + 1, end)
            } else {
                "Invalid email or password"
            }
        } else {
            e.message ?: "Login failed"
        }

        Napier.e("Error in loginUser(): $msg", tag=id)
        return ServerLoginResponse(
            success = false,
            message = msg
        )
    }
}

suspend fun checkAndRestoreSession(): ServerLoginResponse {
    val id = "checkAndRestoreSession"

    if (!SupabaseSession.hasActiveClient()) {
        Napier.e("Supabase client not initialized, unable to restore session", tag=id)
        return ServerLoginResponse(
            success = false,
            message = "Supabase client not initialized"
        )
    }

    val storedSession = SessionStorage.retrieveSession() ?: return ServerLoginResponse(
        success = false,
        message = "No existing session"
    )
    Napier.d("Stored session found", tag=id)

    try {
        // Validate the stored session with our server and get back the decrypted session
        val response = HttpSession.client.post("/auth/validate-session") {
            contentType(ContentType.Text.Plain)
            setBody(storedSession)
        }

        if (response.status.value != 200) {
            Napier.w("Server rejected stored session", tag=id)
            SessionStorage.clearSession()
            return ServerLoginResponse(
                success = false,
                message = "Session expired - please login again"
            )
        }
        Napier.d("Stored session validated and decrypted", tag=id)

        // Parse the validated session from server response
        val oldSession = Json.decodeFromString<UserSession>(response.bodyAsText())
        val authClient = SupabaseSession.client().auth

        // Trade our old refresh token for a new session
        val newSession = try {
            authClient.refreshSession(refreshToken = oldSession.refreshToken)
        } catch (e: Exception) {
            Napier.e("Exception during refreshSession: ${e.message}")
            SessionStorage.clearSession()
            return ServerLoginResponse(
                success = false,
                message = "Session refreshfailed"
            )
        }
        Napier.d("Session refreshed", tag=id)

        // Import session into Supabase client
        try {
            authClient.importSession(
                session = newSession,
                autoRefresh = true
            )
        } catch (e: Exception) {
            Napier.e("Exception during importSession: ${e.message}")
            SessionStorage.clearSession()
            return ServerLoginResponse(
                success = false,
                message = "Session import failed"
            )
        }
        Napier.d("Session imported", tag=id)

        // Load the customer data
        CustomerSession.load(newSession.user!!)
        Napier.d("Customer session loaded", tag=id)

        // Now that we have a valid session, package it and store it.
        return SessionManagementService().processAndStoreSession()

    } catch (e: Exception) {
        Napier.e("Error in session restoration: ${e.message}")
        return ServerLoginResponse(
            success = false,
            message = "Session restoration error"
        )
    }
}

/**
 *  Welcomes the user back after they've created and verified an account.
 */
suspend fun welcomeUser(): ServerLoginResponse {
    val client = SupabaseSession.client()
    val user = client.auth.currentUserOrNull()
    return if (user != null) {
        CustomerSession.load(user)
        val customer = CustomerSession.get()
        ServerLoginResponse(true, "Welcome back, ${customer.first_name}")
    } else {
        ServerLoginResponse(false, "You are not logged in")
    }
}

