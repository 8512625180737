package auth

import io.github.jan.supabase.auth.auth
import session.SupabaseSession
import utils.myLog

suspend fun logoutUser(): Boolean {
    val client = SupabaseSession.client()

    try {
        client.auth.signOut()
        myLog("Sign out successful")
        return true
    } catch (e: Exception) {
        myLog("Sign out exception: $e")
        return false
    }
}


