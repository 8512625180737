package database

import io.github.jan.supabase.auth.auth
import io.github.jan.supabase.postgrest.from
import io.github.jan.supabase.postgrest.query.Columns
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import model.JsonStringPack
import session.HttpSession
import session.SupabaseSession
import utils.myLog


/*
 * Fetches the list of formulas from the database.
 */
suspend fun fetchFormulaList(): List<String> {
    val client = SupabaseSession.client()
    val user = client.auth.currentUserOrNull() ?: throw IllegalStateException("User is not logged in")

    val response = client.from("formulas")
        .select(columns = Columns.list("title_key"))
        .decodeList<Map<String, String>>()
    return response.map { it["title_key"] ?: "" }
}




/*
 * Gets the list of formulas from the database, then calls the selectFormula interface function in jsMain,
 * passing in the list of formulas. If there is an error, catches it and sends the error message to
 * selectFormulaError(). This function is called from the jsMain code.
 */
fun getFormulaList() {
    CoroutineScope(Dispatchers.Main).launch {
        try {
            val formulas = fetchFormulaList()
            selectFormula(formulas)
        } catch (e: Exception) {
            myLog("Load formula list failed with exception: ${e.message}")
            selectFormulaError("Error loading formulas: " + (e.message ?: "Unknown error"))
        }
    }
}

/*
 * Fetches the formula from the database with the given title_key.
 */
suspend fun fetchFormula(titleKey: String): JsonStringPack {
    val client = SupabaseSession.client()
    val user = client.auth.currentUserOrNull() ?: throw IllegalStateException("User is not logged in")

    val response = client.from("formulas")
        .select(columns = Columns.list("formula, rollups")) {
            filter {
                eq("title_key", titleKey)
            }
        }.decodeSingle<Map<String, String>>()
    val formulaJson = response["formula"] ?: ""
    val ingredientDbJson = response["rollups"] ?: ""
    val status = if (formulaJson.isEmpty()) "Formula not found" else "success"
    return JsonStringPack(formulaJson, ingredientDbJson, status)
}

/*
 * Gets the formula from the database, then calls the loadFormulaFromJson interface function in jsMain, passing
 * in a JsonStringPack object containing the formula and ingredients. If there is an error, catches it and sends
 * the error message to selectFormulaError(). This function is called from the platform-specific code.
 */
fun getFormula(titleKey: String) {
    CoroutineScope(Dispatchers.Default).launch {
        try {
            val jsp = fetchFormula(titleKey)
            loadFormulaFromJson(jsp)
        } catch (e: Exception) {
            myLog("Load formula failed with exception: ${e.message}")
            selectFormulaError("Error loading formula: " + (e.message ?: "Unknown error"))
        }
    }
}

suspend fun deleteFormulaFromDb(titleKey: String) {
    val client = SupabaseSession.client()
    val user = client.auth.currentUserOrNull() ?: throw IllegalStateException("User is not logged in")

    client.from("formulas")
        .delete {
            filter {
                eq("title_key", titleKey)
            }
        }
}

fun deleteFormula(titleKey: String) {
    CoroutineScope(Dispatchers.Default).launch {
        try {
            deleteFormulaFromDb(titleKey)
            getFormulaList()
        } catch (e: Exception) {
            myLog("Delete formula failed with exception: ${e.message}")
            selectFormulaError("Error deleting formula: " + (e.message ?: "Unknown error"))
        }
    }
}