package auth

import io.github.jan.supabase.auth.auth
import io.github.jan.supabase.auth.providers.builtin.Email
import kotlinx.serialization.json.jsonObject
import model.*
import session.HttpSession
import session.SupabaseSession
import utils.myLog

// TODO: Move this to the server.

suspend fun HttpSession.signUpUser(userParams: UserLoginParams, newCustomerData: NewCustomerData): ServerLoginResponse {
    myLog("Sign up ${userParams.email}")
    val client = SupabaseSession.client()
    var message = "Sign up successful"
    var success = true

    if (UI.REQUIRE_ACCESS_CODE && newCustomerData.access_code != "B4k3r5") {
        message = "Invalid access code"
        success = false
        return ServerLoginResponse(success, message)
    }

    try {
        val json = JSON_FORMAT.encodeToJsonElement(NewCustomerData.serializer(), newCustomerData).jsonObject
        val redirectUrl = if (UI.USE_LOCALHOST) "http://localhost:8080/" else "https://bakersformulary.com/"
        client.auth.signUpWith(provider = Email, redirectUrl = redirectUrl) {
            this.email = userParams.email
            this.password = userParams.password
            this.captchaToken = userParams.captchaToken

            data = json
            // This data is available as metadata in the user object.
        }
    } catch (e: Exception) {
        message = e.message ?: "Sign up failed"
        success = false
    }

    return ServerLoginResponse(success, message)
}