package database

import dev.fritz2.core.Lenses
import kotlinx.serialization.Serializable
import model.JSON_FORMAT
import utils.myLog
import utils.myLogError

@Lenses
@Serializable
data class Formula(
    val title:              String = "",
    val author:             String = "",
    val basedOn:            String = "",
    val nPieces:            Double = 0.0,
    val pieceSize:          Double = 0.0,
    val totalSize:          Double = 0.0,
    val stages:             List<Stage> = emptyList(),
    val newStageRequest:    Boolean = false,
    val newStageType:       StageType = StageType.overall,
    val nextPreferment:     Int = 1,
    val nextSoaker:         Int = 1,
)
{
    companion object {
        fun emptyFormula(): Formula {
            // Create an empty formula with one stage and one ingredient
            val emptyIngredient = Ingredient()
            val emptyStage = Stage(ingredients = listOf(emptyIngredient), name = "Overall Formula")
            val emptyF = Formula(stages = listOf(emptyStage))
            return emptyF
        }
    }

    fun loadJson(json: String): Formula {
        val f = JSON_FORMAT.decodeFromString<Formula>(json)
        myLog("Formula.loadJson: f.title = ${f.title}, f.newStageRequest = ${f.newStageRequest}")
        return f.copy(newStageRequest = false)
    }

    // Throws an exception if the stageKey is not found
    fun getStage(stageKey: String): Stage {
        return this.stages.first { it.key == stageKey }
    }

    fun addStage(s: Stage): Formula {
        // If s is a final stage, it goes at the end.
        if (s.stageType == StageType.final) {
            return this.copy(stages = this.stages + s)
        } else {
            // If there is a final stage, s goes before it.
            val finalStage = this.stages.last()
            if (finalStage.stageType == StageType.final) {
                val newStages = this.stages.dropLast(1) + s + finalStage
                return this.copy(stages = newStages)
            } else {
                // Otherwise s goes at the end.
                return this.copy(stages = this.stages + s)
            }
        }
    }

    fun updateStage(newStage: Stage): Formula {
        val newStages = this.stages.map { stage ->
            if (stage.key == newStage.key) newStage else stage
        }
        return this.copy(stages = newStages)
    }

    fun renameStage(stageKey: String, newName: String): Formula {
        myLog("renameStage to: $newName")
        // Three things have to happen:
        // 1. Update the name of the stage in the formula
        // 2. Update the name of the rollup in the IngredientDatabase
        // 3. Update the name of the rollup in any other stages that use it

        // If the stage we're updating is at index n, then every rollup that uses it is at index >n.
        val thisIdx = this.stages.indexOfFirst { it.key == stageKey }
        if (thisIdx == -1) {
            myLog("renameStage: stage not found")
            return this
        }

        // For each stage from thisIdx to end of formula, update the rollup name in the list of ingredients
        val newStages = this.stages.mapIndexed { index, thisStage ->
            if (index >= thisIdx) {
                val newIngredients = thisStage.ingredients.map { i ->
                    if (i.rollupStageKey == stageKey) i.copy(name = newName)
                    else i
                }
                thisStage.copy(
                    ingredients = newIngredients,
                    name = if (index == thisIdx) newName else thisStage.name
                )
            } else {
                thisStage
            }
        }

        // Update the IngredientDatabase. If the rollup is not found (user has renamed overall or final),
        // this safely does nothing.
        val db = getIngredientDatabase().renameRollup(stageKey, newName)
        setIngredientDatabase(db)

        // Update the formula
        return copy(stages = newStages)
    }

    fun addIngredient(): Formula {
        val stage = stages[0]
        val newStage = stage.copy(ingredients = stage.ingredients + Ingredient())
        val newStages = stages.mapIndexed { index, thisStage -> if (index == 0) newStage else thisStage }
        return copy(stages = newStages)
    }

    fun replaceIngredient(stageKey: String, oldIngKey: String, newIngName: String): Formula {
        // Get the new ingredient from the ingredient database
        val stage = getStage(stageKey)
        val db = getIngredientDatabase()
        val newIngredient = db.findIngredient(newIngName)
        val oldIngredient = stage.getIngredient(oldIngKey)
        // If the old ingredient is a RollUp, mark it as not used
        if (oldIngredient.isRollup) {
            db.updateIngredient(oldIngredient.copy(isUsed = false))
        }
        setIngredientDatabase(db)

        // Put the old key into the new ingredient. Also transfer qty, percent and units. If the new ingredient is a RollUp, mark it used.
        val newIngredientWithKey = newIngredient.copy(
            key = oldIngKey,
            qty = oldIngredient.qty,
            percent = oldIngredient.percent,
            units = oldIngredient.units,
            isUsed = newIngredient.isRollup, // If this is a RollUp, mark it used
        )
        val newStage = stage.updateIngredient(newIngredientWithKey)
        return updateStage(newStage)
    }

    fun deleteIngredient(stageKey: String, ingredientKey: String): Formula {
        val stage = getStage(stageKey)
        val stageIdx = stages.indexOfFirst { it.key == stageKey }
        if (stageIdx == -1) {
            myLogError("deleteIngredient: stage not found")
            return this
        }

        val isRollup = stage.getIngredient(ingredientKey).isRollup

        // Create a new list of stages that will replace the old one. Initialize it with the existing list.
        val newStageList = stages.toMutableList()
        var emptyStageIdx = -1

        // Create a list of stages from the current stage to the end of the formula. Can include overall stage.
        val hasFinal = stages.last().stageType == StageType.final
        val midStages = stages.subList(stageIdx, stages.size - if (hasFinal) 1 else 0)

        // Delete the ingredient from any stage that uses it.
        // Doesn't delete from final, because final is recalculated from other stages.
        midStages.forEachIndexed { i, midStage ->
            val idx = i + stageIdx
            newStageList[idx] = midStage.deleteIngredient(ingredientKey)
            // If this stage now has no ingredients, mark it for deletion. There is no logical situation where
            // there should be more than 1 empty stage. It could happen if there were multiple stages that consist only
            // of 1 ingredient. Or if there's a stage that consists of only the rollup from the stage from which we're deleting
            // the last ingredient. To manage that, this would have to be recursive.
            if (newStageList[idx].ingredients.isEmpty()) {
                if (emptyStageIdx != -1) myLogError("deleteIngredient: multiple empty stages")
                emptyStageIdx = idx
            }
        }

        // If the deleted ingredient is a rollup, mark it unused in the ingredient database
        if (isRollup) {
            myLog("deleteIngredient: marking rollup unused")
            val db = getIngredientDatabase()
            val rollup = db.getIngredient(ingredientKey)
            myLog("deleteIngredient: rollup.name = ${rollup.name}")
            setIngredientDatabase( db.updateIngredient(rollup.copy(isUsed = false)) )
        }

        // Delete any stages that have no ingredients
        var tempFormula = copy(stages = newStageList.toList())
        if (emptyStageIdx != -1) {
            myLog("deleteIngredient: deleting empty stage")
            tempFormula = tempFormula.deleteStage(newStageList[emptyStageIdx].key)
        }

        return tempFormula
    }

    fun deleteStage(stageKey: String): Formula {
        val stage = getStage(stageKey)
        val isFinal = stage.stageType == StageType.final

        // Create a new list of stages that will replace the old one. Initialize it with the existing list.
        val newStageList = stages.toMutableList()

        // If the stage we're deleting is not the final stage, there's a rollup.
        // Find any use of the rollup in another stage, then delete the rollup.
        if (!isFinal) {
            val db = getIngredientDatabase()
            val rollup = db.getRollup(stageKey)
            if (rollup != null) {
                if (rollup.isUsed) {
                    // Find the stage that uses the rollup
                    val stageWithRollup = stages.firstOrNull { s ->
                        s.ingredients.any { i ->
                            i.rollupStageKey == stageKey
                        }
                    }
                    if (stageWithRollup != null) {
                        // Delete the rollup from the stage
                        val newStage = stageWithRollup.deleteIngredient(rollup.key)
                        val idx = stages.indexOf(stageWithRollup)
                        if (idx != -1) {
                            newStageList[idx] = newStage
                        } else {
                            myLogError("deleteStage: failed to find index of stage with rollup")
                        }
                    } else {
                        myLogError("deleteStage: stage with rollup not found")
                    }
                }
                val newDb = db.deleteIngredient(rollup)
                setIngredientDatabase(newDb)
            } else {
                myLogError("deleteStage: rollup not found in ingredient database")
            }
        }

        // Delete the stage
        if (newStageList.remove(stage)) myLog("Formula.deleteStage: Stage removed. Remaining stages = ${newStageList.map{ it.name }}")
        else myLogError("Formula.deleteStage: failed to remove stage")

        return copy(stages = newStageList.toList())
    }

    fun updateNPieces(newPieces: Double): Formula {
        val newTDW = newPieces * this.pieceSize
        return copy(nPieces = newPieces, totalSize = newTDW)
    }

    fun updatePieceSize(newPieceSize: Double): Formula {
        val newTDW = this.nPieces * newPieceSize
        return copy(pieceSize = newPieceSize, totalSize = newTDW)
    }

    /*
     * Calculates percentages based on grams
     */
    fun calcPct(): Formula {
        // Confirm that all ingredients are in grams. If not, throw an exception.

        val newStageList = mutableListOf<Stage>()
        var newIngredients : List<Ingredient>

        stages.forEach { s ->
            val flourWeight = when (s.stageType) {
                StageType.overall -> {
                    s.ingredients.sumOf { if (it.isFlour) it.qty else 0.0 }
                }
                StageType.preferment -> {
                    // Find the flour ingredients from the list of ingredients for this stage; multiply the Qty of each by the
                    // percent of prefermented flour, and add them up. This is the flour weight for this stage. Then
                    // compute the percent of each ingredient by dividing the Qty by the flour weight.
                    s.ingredients.sumOf { if (it.isFlour) it.qty * s.prefermentedFlour else 0.0 }
                }
                StageType.soaker -> {
                    // All ingredients in a soaker are 100%. Use the Qty of the first ingredient.
                    s.ingredients.first().qty
                }
                StageType.final-> 0.0  // No percentages for final stage
            }
            newIngredients = s.ingredients.map { it.copy(percent = if (flourWeight == 0.0) 0.0 else it.qty / flourWeight) }
            val newStage = s.copy(ingredients = newIngredients)
            newStageList.add(newStage)
        }
        return copy(stages = newStageList.toList())
    }

    fun createFinalStage(): Formula {
        myLog("Formula.createFinalStage()")
        val nonOverallStages = stages.filter { it.stageType != StageType.overall }
        if (nonOverallStages.isNotEmpty()) {
            myLog("Formula.createFinalStage: calling recalc(true)")
            return recalc(true,)
        } else {
            return this
        }
    }
}
