package database

import dev.fritz2.core.Lenses
import io.github.jan.supabase.auth.auth
import io.github.jan.supabase.postgrest.from
import io.github.jan.supabase.postgrest.query.Order
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import model.JSON_FORMAT
import model.JsonStringPack
import session.HttpSession
import session.SupabaseSession
import utils.myLog
import utils.myLogError

expect fun loadIngredientsFromDatabase(db: IngredientDatabase)
expect fun loadIngredientsError(message: String)

expect fun getIngredientDatabase(): IngredientDatabase
expect fun setIngredientDatabase(db: IngredientDatabase)


@Serializable
data class IngredientDataInsert(
    val owner_id: Long?,
    val name: String,
    val is_flour: Boolean,
    val units: Units,
    val conversion: Double,
    val conv_unit: Units,
    val group: IngredientGroup,
    val temp_key: String = "", // Save the key so you can attach the ingredient id to the ingredient in the IngredientDatabase
)

@Serializable
data class IngredientDataUpdate(
    val id: Long,
    val owner_id: Long?,
    val name: String,
    val is_flour: Boolean,
    val units: Units,
    val conversion: Double,
    val conv_unit: Units,
    val group: IngredientGroup,
    val temp_key: String? = null,
)

@Lenses
@Serializable
data class IngredientDatabase (
    val ingredients: List<Ingredient> = emptyList()
)
{
    companion object {}

    private suspend fun fetch(group: IngredientGroup): IngredientDatabase{
        val client = SupabaseSession.client()
        // val user = client.auth.currentUserOrNull() ?: throw IllegalStateException("User is not logged in")

        val response = client.from("ingredients")
            .select {
                if (group != IngredientGroup.All) {
                    filter {
                        eq("group", group.name.lowercase())
                    }
                }
                order(column = "name", order = Order.ASCENDING)
            }.decodeList<IngredientDataUpdate>()

        return IngredientDatabase(ingredients = response.map { data ->
                Ingredient(
                    id          = data.id,
                    ownerId     = data.owner_id,
                    name        = data.name,
                    isFlour     = data.is_flour,
                    units       = data.units,
                    conversion  = data.conversion,
                    convUnit    = data.conv_unit,
                    group       = data.group
                )
            }
        )
    }

    fun load(group: IngredientGroup) {
        CoroutineScope(Dispatchers.Default).launch {
            try {
                val db = fetch(group)
                loadIngredientsFromDatabase(db)
            } catch (e: Exception) {
                myLogError("Load formula failed with exception: ${e.message}")
                loadIngredientsError("Error loading ingredients: " + (e.message ?: "Unknown error"))
            }
        }
    }

    /*
     * Saves the special ingredients to the database under this user's id.
     */
    private suspend fun _save() {
        val client = SupabaseSession.client()
        val user = client.auth.currentUserOrNull() ?: throw IllegalStateException("User is not logged in")
        val customer = CustomerSession.get()
        val ingredients = this.ingredients.filter { it.ownerId == customer.id }
        if (ingredients.isEmpty()) {
            myLog("No special ingredients to save")
            return
        }

        // To insert a new ingredient, omit the id field. The database will create it.
        val insertData = ingredients.filter { it.id == 0L }.map { i ->
            IngredientDataInsert(
                owner_id = i.ownerId,
                name = i.name,
                is_flour = i.isFlour,
                units = i.units,
                conversion = i.conversion,
                conv_unit = i.convUnit,
                group = i.group,
                temp_key = i.key,
            )
        }

        insertData.forEach {
            myLog("_save(): Inserting ingredient ${it.name} group ${it.group} owner ${it.owner_id}")
        }

        // To update an existing ingredient, include the id field.
        val updateData = ingredients.filter { it.id != 0L && it.ownerId != null && it.saveThis }.map { i ->
            IngredientDataUpdate(
                id = i.id,
                owner_id = i.ownerId,
                name = i.name,
                is_flour = i.isFlour,
                units = i.units,
                conversion = i.conversion,
                conv_unit = i.convUnit,
                group = i.group,
                temp_key = null,
            )
        }

        // Insert the new ingredients, then update them in the IngredientDatabase.
        if (insertData.isNotEmpty()) {
            client.from("ingredients").insert(insertData)

            // Fetch the newly inserted ingredients
            val fetchResult = client.from("ingredients")
                .select() {
                    filter {
                        eq("owner_id" , CustomerSession.get().id)
                    }
                }
                .decodeList<IngredientDataUpdate>()  // Use Update data structure to get the id of the new ingredient

            val fetchedIngredients = fetchResult.map { data ->
                val originalIngredient = this.ingredients.firstOrNull { it.key == data.temp_key }
                originalIngredient?.copy(id = data.id) ?:
                    Ingredient(
                        id = data.id,
                        ownerId = data.owner_id,
                        name = data.name,
                        isFlour = data.is_flour,
                        units = data.units,
                        conversion = data.conversion,
                        convUnit = data.conv_unit,
                        group = data.group
                    ).also { myLog("Ingredient not found for key ${data.temp_key}") }
            }
            val ingredientDatabaseWithIds = updateIngredients(IngredientDatabase(fetchedIngredients))
            setIngredientDatabase(ingredientDatabaseWithIds)
        } else {
            myLog("No new ingredients to save")
        }

        if (updateData.isNotEmpty()) {
            updateData.forEach { data ->
                myLog("_save(): Updating ingredient ${data.name}")
                client.from("ingredients")
                    .update(data) {
                        filter { // Update only if the owner and the ingredient ID match
                            data.owner_id?.let { eq("owner_id", it) }
                            eq("id", data.id)
                        }
                    }
            }
        } else {
            myLog("_save(): No ingredients to update")
        }
    }

    fun save() {
        CoroutineScope(Dispatchers.Default).launch {
            try {
                _save()
            } catch (e: Exception) {
                myLogError("Save special ingredients failed with exception: ${e.message}")
            }
        }
    }

    fun getIngredient(key: String) : Ingredient {
        return this.ingredients.first { i -> i.key == key }
    }

    fun findIngredient(name: String) : Ingredient {
        return this.ingredients.first { i -> i.name == name }
    }

    fun addIngredient(i: Ingredient) : IngredientDatabase {
        return IngredientDatabase(ingredients = this.ingredients + i)
    }

    fun updateIngredient(i: Ingredient) : IngredientDatabase {
        return IngredientDatabase(ingredients = this.ingredients.map { if (it.key == i.key) i else it })
    }

    fun updateIngredients(newDb: IngredientDatabase) : IngredientDatabase {
        return IngredientDatabase(ingredients = this.ingredients.map { currIng ->
            newDb.ingredients.firstOrNull { it.key == currIng.key } ?: currIng
        })
    }

    /*
     * Delete an ingredient from the database. Does not manage any use of the ingredient elesewhere.
     */
    fun deleteIngredient(i: Ingredient) : IngredientDatabase {
        return IngredientDatabase(ingredients = this.ingredients.filter { it.key != i.key })
    }

    /*
     * Update the ingredients in the database, adding any new ingredients that are not already there.
     * In other words, combine the new list with the database.
     */
    fun updateAndAddIngredients(newList: List<Ingredient>) : IngredientDatabase {
        val mapNew = newList.associateBy { it.key }
        val combined = mutableListOf<Ingredient>()

        // Add the items from currDb, replacing with items from newList if key matches
        this.ingredients.forEach { currItem ->
            val objFromNew = mapNew[currItem.key]
            combined.add(objFromNew ?: currItem)
        }

        // Add items from newList that are not in combined list
        val mapCombined = combined.associateBy { it.key }
        newList.forEach { newIng ->
            if (!mapCombined.containsKey(newIng.key)) {
                combined.add(newIng)
            }
        }

        return IngredientDatabase(combined)
    }

    fun getRollup(stageKey: String) : Ingredient? {
        return this.ingredients.firstOrNull { it.isRollup && it.rollupStageKey == stageKey }
    }

    fun renameRollup(stageKey: String, newName: String) : IngredientDatabase {
        val rollup = getRollup(stageKey)
        if (rollup == null) {
            return this
        } else {
            val rollupNewname = rollup.copy(name = newName)
            return this.copy(ingredients = this.ingredients.map { if (it.key == rollupNewname.key) rollupNewname else it })
        }
    }

    fun jsonLoad(jsp: JsonStringPack) : IngredientDatabase {
        val newitems = JSON_FORMAT.decodeFromString<List<Ingredient>>(jsp.ingredientDbJson)
        return IngredientDatabase(this.ingredients.filter{ !it.isRollup } + newitems)
    }

    fun clearRollups() : IngredientDatabase {
        return IngredientDatabase(this.ingredients.filter{ !it.isRollup })
    }

    fun dump() {
        myLog("IngredientDatabase:")
        this.ingredients.forEach { i ->
        myLog("  ${i.name} saveThis: ${i.saveThis}")
        }
    }
}