package session

import auth.SessionManagementService
import io.github.aakira.napier.Napier
import io.github.jan.supabase.SupabaseClient
import io.github.jan.supabase.auth.Auth
import io.github.jan.supabase.auth.auth
import io.github.jan.supabase.auth.status.SessionSource
import io.github.jan.supabase.auth.status.SessionStatus
import io.github.jan.supabase.createSupabaseClient
import io.github.jan.supabase.logging.LogLevel
import io.github.jan.supabase.postgrest.Postgrest
import io.github.jan.supabase.serializer.KotlinXSerializer
import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.cookies.*
import io.ktor.client.plugins.logging.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.io.IOException
import kotlinx.serialization.json.Json
import model.UI
import model.UI.USE_LOCALHOST
import kotlin.random.Random


data class SupaConfig(
    val supabaseUrl: String,
    val supabaseAnonKey: String
)

private fun fetchConfig(): SupaConfig {
    val id = "fetchConfig()"
    return if (UI.USE_DEV_DB) {
        // Development configuration (unchanged)
        val devParts = listOf(
            "eyJhbGciOiJIUzI",
            "1NiIsInR5cCI6IkpXVCJ9",
            ".eyJpc3MiOiJzdXBhYm",
            "FzZSIsInJlZiI6In",
            "hleXBndW5zcnBlb3F",
            "pcXZ2eHByIiwicm9s",
            "ZSI6ImFub24iLCJpYXQi",
            "OjE7MTgwODI2NTksImV4cCI",
            "6MjAzMzY1ODY1OX0.VaZ4vOAMha2Fky4ZtIn_hE_P94kqdAnpD60D6Ou-DQM"
        )
        val devConfig = devParts.joinToString("")
        val devUrl = listOf(
            "https://xeypguns",
            "rpeoqiqvvx",
            "pr.supabase.co"
        ).joinToString("")
        SupaConfig(supabaseUrl = devUrl, supabaseAnonKey = devConfig)
    } else {
        // Production configuration (obfuscated)
        val prodUrlParts = listOf(
            "pd/ftbcbqvt/zejdk{exgqvzc",
            "hzxklvp00;tquui"
        )
        val prodKeyParts = listOf(
            "Jm{sHTjujbQ`8UPtx{D",
            "2hczv2yMKq8rQUFi",
            "Oi7xxeLK/14N{JU",
            "P1lEO{BkN7JDd5Wn",
            "JtN{NzN{O{lUN4FkPjRYZ",
            "qKDMj53cvGnJ7JT",
            "[t:ndjxjJ6SXbkqnfleo[x",
            "WYfjeXf4q3b2:nJ7Jj[m",
            "KoJtJT[{GnZiCYe{KjPjN4dq",
            "Kzf/:KDWYqlJ7JDd",
            "6SoJtJjO2J{VJKjPjdHciKzf"
        )

        val purl = prodUrlParts.joinToString("").decodeTransformedString()
        val pkey = prodKeyParts.joinToString("").decodeTransformedString()
        Napier.d("Supabase URL: $purl", tag=id)
        Napier.d("Supabase key: $pkey", tag=id)
        SupaConfig(
            supabaseUrl = purl,
            supabaseAnonKey = pkey
        )
    }
}

fun String.decodeTransformedString(): String {
    return this.reversed().map { it.code.dec().toChar() }.joinToString("")
}

class SupabaseSession private constructor() {
    companion object {
        private var client: SupabaseClient? = null
        private var initializationInProgress = false

        fun initialize() {
            // Guard against concurrent initialization
            if (initializationInProgress || client != null) {
                return
            }

            try {
                initializationInProgress = true
                val config = fetchConfig()

                client = createSupabaseClient(
                    supabaseUrl = config.supabaseUrl,
                    supabaseKey = config.supabaseAnonKey
                ) {
                    defaultSerializer = KotlinXSerializer(
                        Json {
                            prettyPrint = true
                            isLenient = true
                            ignoreUnknownKeys = true
                            defaultLogLevel = LogLevel.ERROR
                        }
                    )
                    defaultLogLevel = LogLevel.ERROR

                    install(Postgrest)
                    install(Auth)
                }

                // Subscribe to session status changes
                CoroutineScope(Dispatchers.Main).launch {
                    val id="Auth-listener"
                    client?.auth?.sessionStatus?.collect { status ->
                        when(status) {
                            is SessionStatus.Authenticated -> {
                                Napier.d("Authenticated session received", tag=id)
                                when(status.source) {
                                    is SessionSource.Refresh -> {
                                        Napier.d("Session refreshed - updating stored session", tag=id)
                                        SessionManagementService().processAndStoreSession()
                                    }
                                    // Handle other sources if needed
                                    else -> Napier.d("Session source: ${status.source}", tag=id)
                                }
                            }
                            is SessionStatus.RefreshFailure -> {
                                Napier.e("Session refresh failed: ${status.cause}", tag=id)
                                // Maybe clear stored session here?
                            }
                            is SessionStatus.NotAuthenticated -> {
                                if (status.isSignOut) {
                                    Napier.d("User signed out", tag=id)
                                    SessionStorage.clearSession()
                                }
                            }
                            else -> Napier.d("Session status: $status", tag=id)
                        }
                    }
                }
            } catch (e: Exception) {
                Napier.e("Error initializing Supabase: ${e.message}")
                throw e
            } finally {
                initializationInProgress = false
            }
        }

        fun client(): SupabaseClient {
            return client ?: throw IllegalStateException(
                "SupabaseSession not initialized. Call initialize() first."
            )
        }

        // Helper to check initialization status
        fun hasActiveClient(): Boolean {
            return client != null && !initializationInProgress
        }
    }
}

object HttpSession {
    val client = try {
        HttpClient {
            install(ContentNegotiation) {
                json(Json {
                    prettyPrint = true
                    isLenient = true
                    ignoreUnknownKeys = true
                })
            }

            install(Logging) {
                logger = Logger.DEFAULT
                level = io.ktor.client.plugins.logging.LogLevel.INFO
            }

            install(DefaultRequest) {
                url {
                    protocol = if (USE_LOCALHOST) URLProtocol.HTTP else URLProtocol.HTTPS
                    host = if (USE_LOCALHOST) "localhost" else "bakersformulary.com"
                    port = if (USE_LOCALHOST) 8080 else 443
                }
            }

            install(HttpCookies) {
                storage = AcceptAllCookiesStorage()
            }

            install(HttpTimeout) {
                requestTimeoutMillis = 10000
            }

            install(HttpPlainText)

            install(HttpRequestRetry) {
                retryOnExceptionOrServerErrors(maxRetries = 5)
                exponentialDelay()
            }
        }
    } catch (e: Exception) {
        Napier.e("Error creating HttpClient: ${e.message}")
        throw e
    }

    private val failureRandom = Random.Default

    private fun shouldSimulateNetworkFailure(): Boolean {
        if (UI.SIMULATE_NETWORK_OUTAGE) {
            Napier.d("Simulating network failure", tag="shouldSimulateNetworkFailure()")
            return failureRandom.nextDouble() < 0.3
        }
        return false
    }

    init {
        client.plugin(HttpSend).intercept { request ->
            if (shouldSimulateNetworkFailure()) {
                throw SimulatedNetworkException("Simulated network failure")
            }
            execute(request)
        }
    }
}

class SimulatedNetworkException(message: String): IOException(message)

fun isLoggedIn(): Boolean {
    val id = "isLoggedIn"
    return try {
        val client = SupabaseSession.client()
        val currentUser = client.auth.currentUserOrNull()
        currentUser != null
    } catch (e: IllegalStateException) {
        Napier.e("SupabaseSession not initialized, unable to check login: ${e.message}", tag=id)
        // Not initialized yet, so not logged in
        false
    } catch (e: Exception) {
        Napier.e("Error retrieving login: ${e.message}", tag=id)
        false
    }

}
