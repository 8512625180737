package database

import dev.fritz2.core.Handler
import dev.fritz2.core.RootStore
import kotlinx.coroutines.Job
import model.JsonStringPack
import utils.myLog
import io.github.aakira.napier.Napier

actual fun loadIngredientsFromDatabase(db: IngredientDatabase) {
    IngredientDatabaseStore.updateAndAddIngredients(db.ingredients)
}

actual fun loadIngredientsError(message: String) {
    val id = "loadIngredientsError"
    Napier.d("Load ingredients failed with message: $message", tag=id)
}

actual fun getIngredientDatabase(): IngredientDatabase {
    return IngredientDatabaseStore.current
}

actual fun setIngredientDatabase(db: IngredientDatabase) {
    IngredientDatabaseStore.update(db)
}

val idbJob = Job()

object IngredientDatabaseStore: RootStore<IngredientDatabase>(IngredientDatabase(), job = idbJob) {
    val addIngredient: Handler<Ingredient> = handle { db, i ->
        val id = "addIngredient"
        val preSize = db.ingredients.size
        Napier.d("Adding ingredient ${i.name} with isRollup = ${i.isRollup}, size = $preSize", tag=id)
        val updatedDb = db.addIngredient(i)
        Napier.d("Updated ingredient list size = ${updatedDb.ingredients.size}", tag=id)
        updatedDb
    }

    val updateIngredient: Handler<Ingredient> = handle { db, i ->
        val id = "updateIngredient"
        Napier.d("Updating ingredient ${i.name}", tag=id)
        db.updateIngredient(i)
    }

    val updateIngredients: Handler<IngredientDatabase> = handle { currDb, newDb ->
        val id = "updateIngredients"
        Napier.d("Updating ingredients", tag=id)
        currDb.updateIngredients(newDb)
    }

    val updateAndAddIngredients: Handler<List<Ingredient>> = handle { currDb, newList ->
        val id = "updateAndAddIngredients"
        Napier.d("Updating and adding ingredients", tag=id)
        currDb.updateAndAddIngredients(newList)
    }

    val renameRollup: Handler<Pair<String, String>> = handle { db, (stageKey, newName) ->
        val id = "renameRollup"
        Napier.d("Renaming rollup with stageKey = $stageKey to $newName", tag=id)
        db.renameRollup(stageKey, newName)
    }

    val jsonLoad: Handler<JsonStringPack> = handle { db, jsp ->
        val id = "jsonLoad"
        Napier.d("Loading JSON", tag=id)
        db.jsonLoad(jsp)
    }

    val clearRollups: Handler<Unit> = handle { db ->
        val id = "clearRollups"
        Napier.d("Clearing rollups", tag=id)
        db.clearRollups()
    }

    val load: Handler<IngredientGroup> = handle { db, group ->
        val id = "load"
        Napier.d("Loading ingredient group", tag=id)
        db.load(group)
        db
    }
}
