package routing

// import dev.fritz2.dom.*
// import auth.*
import utils.loginErrorAlert
import utils.myLog
import auth.loginPage
import auth.loginUser
import auth.logoutUser
import auth.signUpUser
import auth.signupPage
import auth.signupSuccessPage
import auth.welcomeNewUser
import auth.welcomeUser
import auth.checkAndRestoreSession
import dev.fritz2.core.Handler
import dev.fritz2.core.render
import dev.fritz2.headless.foundation.portalRoot
import dev.fritz2.routing.routerOf
import dev.fritz2.validation.ValidatingStore
import dev.fritz2.validation.valid
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import main.appMain
import model.NewCustomerData
import model.SignUpMessage
import model.UI
import model.UserLoginParams
import money.pricingPanel
import org.w3c.dom.get
import org.w3c.dom.set
import session.HttpSession
import session.SessionStorage
import session.isLoggedIn
import session.SupabaseSession
import utils.myLogError

// import utils.loginErrorAlert

object Router {
    enum class Page {
        LOGIN,
        SIGNUP,
        SIGNUP_SUCCESS, // where new users go after they've signed up
        WELCOME,        // where new users go after they've confirmed their email
        APP,
        PRICING_A,
        PRICING_B,
        PRICING_C,
    }

    private val router = routerOf(Page.LOGIN.name)
    val coroutineScope = CoroutineScope(Dispatchers.Main)

    /*
     * When the user clicks the Login button in the interface, processLogin handles it.
     */
    object UserParamsStore: ValidatingStore<UserLoginParams, Unit, SignUpMessage>(
        initialData = UserLoginParams("", "", ""),
        validation = UserLoginParams.myValidator,
        metadataDefault = Unit,
        job = Job()
    ) {
        val processLogin: Handler<UserLoginParams> = handle { _, new ->
            if (UI.REQUIRE_LOGIN) {
                if (new.email.isNotEmpty() && new.password.isNotEmpty()) {
                    val response = loginUser(new)
                    val message = response.message
                    if (response.success) router.navTo(Page.APP.name) else loginErrorAlert(message)
                } else {
                    loginErrorAlert("Please enter a valid email and password")
                }
            } else {
                val response = loginUser(UserLoginParams("tony@thetiger.com", "4444", new.captchaToken))
                val message = response.message
                if (response.success) router.navTo(Page.APP.name) else loginErrorAlert(message)
            }
            UserLoginParams("", "", "")
        }

        val processLogout: Handler<Unit> = handle {
            logoutUser()
            router.navTo(Page.LOGIN.name)
            UserLoginParams("", "", "")
        }

        val processSignup: Handler<Pair<UserLoginParams, NewCustomerData>> = handle { old, p ->
            val userParams = p.first
            val newCustomerData = p.second
            if (validate(userParams).valid) {
                val response = HttpSession.signUpUser(userParams, newCustomerData)
                val message = response.message
                if (response.success) {
                    router.navTo(Page.SIGNUP_SUCCESS.name)
                } else {
                    loginErrorAlert(message)
                }
            }
            old
        }

        val processNewUserReturn: Handler<Unit> = handle { old ->
            val response = welcomeUser()
            if (response.success) {
                router.navTo(Page.APP.name)
            } else {
                loginErrorAlert(response.message)
            }
            old
        }
    }

    fun protectedRoute(): Boolean {
        if (!isLoggedIn()) {
            val hasStoredSession = SessionStorage.retrieveSession() != null
            if (!hasStoredSession) {
                utils.loginErrorAlert("You have to be logged in to do that")
                router.navTo(Page.LOGIN.name)
                return false
            }
            // Let session restoration handle the transition if we have a stored session
            return false
        }
        return true
    }

    fun routeTo(page: Page) {
        router.navTo(page.name)
    }

    /*
     * Refreshes the screen for captcha-protected forms.
     */
    private fun captchaManager(actionCountKey: String, actionPanel: () -> Unit) {
        val actionCount = sessionStorage[actionCountKey]?.toInt() ?: 0
        if (actionCount >= 1) {
            sessionStorage[actionCountKey] = "0"
            window.location.reload()
        } else {
            sessionStorage[actionCountKey] = (actionCount + 1).toString()
            actionPanel()
        }
    }

    /*
     * Checks for a valid auth cookie, and logs in the user if it's found. If not, bring up the login page.
     */
    private fun loginManager() {
        coroutineScope.launch {
            val sessionResponse = checkAndRestoreSession()
            if (sessionResponse.success) {
                myLog("Session restored successfully")
                routeTo(Page.APP)
            } else {
                // On initial load or expired session, show login page
                captchaManager("loginCount", ::loginPage)
            }
        }
    }

    fun start() {
        render {
            section {
                router.data.render { page ->
                    myLog("Routing to page: $page")
                    try {
                        when(Page.valueOf(page)) {
                            Page.APP -> appMain()
                            Page.LOGIN -> loginManager()
                            Page.SIGNUP -> captchaManager("signupCount", ::signupPage)
                            Page.WELCOME -> welcomeNewUser()
                            Page.SIGNUP_SUCCESS -> signupSuccessPage()
                            Page.PRICING_A -> pricingPanel(0.0, 9.99, 14.99)
                            Page.PRICING_B -> pricingPanel(0.0, 7.99, 12.99)
                            Page.PRICING_C -> pricingPanel(5.99, 9.99, 14.99)
                        }
                    } catch (e: IllegalArgumentException) {
                        myLog("Error: Invalid page value : $page")
                    }
                }
            }
            portalRoot()
        }
    }
}