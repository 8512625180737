package session

import io.github.aakira.napier.Napier
import kotlinx.browser.window

actual object SessionStorage {
    private const val id = "LocalStorage"
    private const val SESSION_KEY = "encrypted_session"

    actual fun storeSession(encryptedSession: String) {
        window.localStorage.setItem(SESSION_KEY, encryptedSession)
        Napier.d("Encrypted session stored", tag=id)
    }

    actual fun retrieveSession(): String? {
        return window.localStorage.getItem(SESSION_KEY)
    }

    actual fun clearSession() {
        window.localStorage.removeItem(SESSION_KEY)
        Napier.d("Session cleared", tag=id)
    }
}